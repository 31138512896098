//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import html2canvas from "html2canvas";
import QRCode from 'qrcode';
import wx from 'weixin-js-sdk';
import axios from 'axios';
// import POSTER from '@/assets/json/shop/index';
import LOGGER from '@/apis/log';

const DEFAULT_APPID = 'wx60ced1c8de7908d5'
export default {
	metaInfo () {
      return {
			title: '报价单预览',
		}
	},
	data: ()=> ({
		dataURL: '',
		imgUrl: '',
		appId: '',
		quotationId:0,
		productId:0,
		shopId:0,
		quotationInfo: {},
		productInfo: {},
		preOrderInfo: {},
		qrCodeUrl:'',
		feeList:[],
		deliver_date:'',
		rate: 1,
		airline:'',
        transit:'',
	}),
	created: function() {
		let {appId = 'wx60ced1c8de7908d5'} = this.$route.query
		if(appId) {
			this.appId = appId
		}
		let {shop_id = 0, product_id = 0, quotation_id = 0} = this.$route.params
		if(quotation_id) {
			this.quotationId = quotation_id
		}
		if(shop_id) {
			this.shopId = shop_id
		}
	},
	mounted: async function() {
		this.initWxConfig()
		if(this.quotationId) {
			await this.loadInfo()
		}
		if(this.shopId && this.quotationId) {
			this.codeChange()
		}
		let toast = this.$toast.loading('生成中, 请稍后', 0)
		let that=this
		setTimeout(async function () {
			await that.html2basr64()
		},100)
		toast.clear()



		
	},
	methods: {
		codeChange: function() {
			let canvas = document.createElement("canvas");
			canvas.width = "100%";
			canvas.height = "100%";
			let qrcode='http://agent-mall.efreight.cn/quotation/'+this.shopId+'/'+this.quotationId
			QRCode.toCanvas(canvas, qrcode, error => {
				if (error) {
					console.log(error);
				} else {
					this.qrCodeUrl = canvas.toDataURL("image/jpeg");
				}
			});
		},
		getRequest: function(url){
			return new Promise((resolve, reject)=> {
				axios.get(url)
				.then(res=> {
					resolve(res)
				}).catch(err=> {
					console.log(err)
				})
			})
		},
		loadInfo: async function(){
			let url='/api-mall/quotation/' + this.quotationId,
				res = await this.getRequest(url)
			if(!!res){
				this.quotationInfo = res.data.quotation
				this.productInfo = res.data.product
				this.preOrderInfo=res.data.preOrder
				if(!!this.quotationInfo){
					this.airline=!!this.quotationInfo.airline ? this.quotationInfo.airline :(!!this.productInfo && !!this.productInfo.airline ? this.productInfo.airline :'') 
					this.transit=!!this.quotationInfo.transit && this.quotationInfo.transit !='undefined' ? this.quotationInfo.transit : ''
					this.deliver_date=this.quotationInfo.deliver_date.substring(this.quotationInfo.deliver_date.length-5).replace( new RegExp('-','g'), '.')
					this.feeList =JSON.parse(this.quotationInfo.fee_list_string)
					await this.getRate()
				}
			}
		},
		getRate: async function(){
			var that=this
			for (var i = 0; i < that.feeList.length; i++) {
				if(that.feeList[i].currencyUnit!='CNY'){
					await that.loadRate(that.feeList[i].currencyUnit)
					return
				}	
			}
		},
		loadRate: async function(currency){
			let that=this,
				url = '/api-yc/ae/exchangeRate?exchangeRateDate',
				res = await this.getRequest(url)
			if(!!res){
				for (var j = 0; j < res.data.data.length; j++) {
					if(currency == res.data.data[j].currency){
						let exchangeRate= res.data.data[j].exchangeRate
						that.rate= exchangeRate
					}
				}
			}
		},
		longPress: function() {
			html2canvas(this.$refs.quotationDetail).then(canvas => {
				let dataURL = canvas.toDataURL("image/png");
				this.imgUrl = dataURL;
				if (this.imgUrl !== "") {
					this.dialogTableVisible = true;
				}
				console.log(dataURL)
				wx.previewImage({
					current: dataURL, // 当前显示图片的http链接
					urls: [dataURL],
					complete: err=> {
						console.log(err)
					}
				})
			});
		},

		html2basr64: function() {
			let _this = this
			return new Promise((resolve, reject)=> {
				html2canvas(_this.$refs.quotationDetail).then(canvas => {
					let dataURL = canvas.toDataURL("image/jpeg");
					_this.imgUrl = dataURL;
					if(_this.imgUrl) {
						// _this.$toast.success('生成图片成功')
						resolve()
					} else {
						_this.$toast.fail('生成图片失败, 请联系客服')
					}
				});
			})
		},

		postMsg: async function() {
			await this.html2basr64()
			let _this = this
			let imgKey = new Date().getTime()
			console.log(_this.imgUrl)
			wx.miniProgram.postMessage({data: {baseImg: _this.imgUrl , imgKey: imgKey}})
			wx.miniProgram.redirectTo({
				url: '/shop/newList/quotation/preview?imgKey=' + imgKey,
			})
		},

		initWxConfig: function() {
			let url =  window.location.href
			url = url.split('#')[0]  
			console.log('/api-pass/wechat/h5/sign/share' + '?app_id=' + this.appId + '&url=' + url)
			const promise = axios.get('/api-pass/wechat/h5/sign/share' + '?app_id=' + this.appId + '&url=' + url);
			promise.then(res => {
				if (res.data.code == 200) {
					const sign = res.data.data;
					this._wxConfigJSSDK(sign);
				}
			});
			promise.catch((err) => {
				console.log(err.response);
			})
		},
		
		_wxConfigJSSDK: function(sign){
			console.log(sign)
			console.log(parseInt(new Date().getTime() / 1000), sign.timestamp, new Date().getTime() / 1000 - sign)
			wx.config({
				debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
				appId: sign.appId + '', // 必填，公众号的唯一标识
				timestamp: parseInt(sign.timestamp), // 必填，生成签名的时间戳
				nonceStr: sign.nonceStr + '', // 必填，生成签名的随机串
				signature: sign.signature + '',// 必填，签名
				jsApiList: ['chooseImage', 'downloadImage', 'previewImage', 'uploadImage'] // 必填，需要使用的JS接口列表
			})
			wx.ready(function(){
                wx.checkJsApi({
                    jsApiList: [
                        'chooseImage',
                        'previewImage',
                        'uploadImage',
                        'downloadImage'
                    ],
                    success: function (res) {
                        if (res.checkResult.getLocation == false) {
                            console.error('你的微信版本太低，不支持微信JS接口，请升级到最新的微信版本！');
                            return;
                        }else{
							console.log('授权成功', res)
                        }
					},
					fail: err=> {
						console.log(err)
					}
                });
			});

			wx.error(function(res){
				console.error(res)
			});
		},
	}
}
